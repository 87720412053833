
import update from 'immutability-helper';

const initialState = {
  isFetching: false,
  isValid: false,
  isError: false,
  awaitingAuth: false,
  error: null,
  manifest: {},
}

const singleManifestReducer = (manifest=initialState, action) => {
  switch (action.type) {
    case "manifest/request":
      return Object.assign({}, manifest, {isFetching: true,
                                          isError: false})

    case "manifest/receive":
      return Object.assign({}, manifest, {isFetching: false,
                                          isValid: true,
                                          awaitingAuth: false,
                                          manifest: action.manifest,
                                          isError: false})

    case "manifest/error":
      return Object.assign({}, manifest, {isFetching: false,
                                         isValid: false,
                                         awaitingAuth: false,
                                         isError: true,
                                         error: action.error})
    case "manifest/auth":
      return Object.assign({}, manifest, {isFetching: false,
                                         isValid: false,
                                         isError: false,
                                         awaitingAuth: true})

    default:
      return manifest
  }

}

const manifestReducer = (manifests={}, action) => {
  let slice = initialState;
  let repoDigest = [action.repository, action.digest].join("+")
  switch (action.type) {
    case "manifest/request":
    case "manifest/receive":
    case "manifest/auth":
    case "manifest/error":
      if (repoDigest in manifests) {
        slice = manifests[repoDigest];
      }

      slice = singleManifestReducer(slice, action)
      return Object.assign({}, manifests, {[repoDigest]: slice})

    default:
      return manifests
  }
}

export const selectManifest = (repository, digest) => (state) => {
  let repoDigest = [repository, digest].join("+")
  if (repoDigest in state.manifests) {
    return state.manifests[repoDigest];
  } else {
    return initialState;
  }
}

export default manifestReducer
