
import update from 'immutability-helper';

const initialState = {
  isFetching: false,
  isValid: false,
  isError: false,
  awaitingAuth: false,
  error: null,
  tagDigest: null,
  digests: [],
}

const singleManifestListReducer = (digests=initialState, action) => {
  switch (action.type) {
    case "manifest_list/request":
      return Object.assign({}, digests, {isFetching: true,
                                         isError: false})

    case "manifest_list/receive":
      return Object.assign({}, digests, {isFetching: false,
                                         isValid: true,
                                         awaitingAuth: false,
                                         tagDigest: action.tagDigest,
                                         digests: action.digests.map(x => x),
                                         isError: false})

    case "manifest_list/error":
      return Object.assign({}, digests, {isFetching: false,
                                         isValid: false,
                                         awaitingAuth: false,
                                         isError: true,
                                         error: action.error})

    case "manifest_list/auth":
      return Object.assign({}, digests, {isFetching: false,
                                         isValid: false,
                                         isError: false,
                                         awaitingAuth: true})

    default:
      return tags
  }

}

const manifestListReducer = (manifestLists={}, action) => {
  let slice = initialState;
  let repoTag = [action.repository, action.tag].join("+")
  switch (action.type) {
    case "manifest_list/request":
    case "manifest_list/receive":
    case "manifest_list/error":
    case "manifest_list/auth":
      if (repoTag in manifestLists) {
        slice = manifestLists[repoTag];
      }

      slice = singleManifestListReducer(slice, action)
      return Object.assign({}, manifestLists, {[repoTag]: slice})

    default:
      return manifestLists
  }
}

export const selectManifestList = (repository, tag) => (state) => {
  let repoTag = [repository, tag].join("+")
  if (repoTag in state.manifestLists) {
    return state.manifestLists[repoTag];
  } else {
    return initialState;
  }
}

export default manifestListReducer
