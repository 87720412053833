import "core-js/stable";
import "regenerator-runtime/runtime";
import thunk from 'redux-thunk';

import './common.scss'
import 'bootstrap'

import Header from './components/Header.jsx';
import Repositories from './components/Repositories.jsx';
import Tags from './components/Tags.jsx';
import Images from './components/Images.jsx';
import About from './components/About.jsx';
import Auth from './components/Auth.jsx';
import Delete from './components/Delete.jsx';

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers'

const store = createStore(rootReducer, 
  window.__REDUX_DEVTOOLS_EXTENSION__ ? 
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__(),
  ) :
  applyMiddleware(thunk)
)

ReactDOM.render(
  <Provider store={store}>
    <Router>
			<Auth />
			<Delete />
      <Switch>
        <Route path="/" exact component={Repositories} />
        <Route path="/:repository+::tag" exact component={Images} />
        <Route path="/:repository+" exact component={Tags} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('app-root')
);
