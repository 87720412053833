
const initialState = {
	awaitConfirmation: false, 
  confirmed: false,
  repository: "",
  tag: "",
  tagDigest: ""
}

export const delReducer = (delState=initialState, action) => {
	switch (action.type) {
		case "delete/prompt":
			return {
        awaitConfirmation: true,
        confirmed: false,
        repository: action.repository,
        tag: action.tag,
        tagDigest: action.tagDigest
      }
    case "delete/confirm":
			return Object.assign({}, delState, {
        awaitConfirmation: false,
        confirmed: true
      })
    case "delete/reset":
			return {
        awaitConfirmation: false,
        confirmed: false,
        repository: "",
        tag: "",
      }
		default:
			return delState
	}
}

export const selectDeleteConfirmation = (store) => store.del

export default delReducer

