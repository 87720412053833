
import {
  getRepositories,
  getTags,
  deleteTag,
  getManifest,
  getImage,
  getToken
} from '../lib/registry.js'
import { selectAwaitingAuth } from '../reducers/auth.js'

export const authMissingCredentialsAction = () => ({
	type: "auth/missing_credentials"
})

export const authMissingTokenAction = (realm, service, scope) => ({
	type: "auth/missing_token",
  realm,
  service, 
  scope
})

export const authReceiveToken = (token) => ({
	type: "auth/receive_token",
  token,
})

export const authReceiveCredentialsAction = (username, password) => ({
	type: "auth/receive_credentials",
  username,
  password
})

export const resolveAuthIssue = (username, password) => {
  return (dispatch, getState) => {
    dispatch(authReceiveCredentialsAction(username, password))
    let auth = getState().auth
    if (auth.missingToken) {
      dispatch(unauthorizedAction({type: "Bearer",
        params: {
          realm: auth.realm,
          scope: auth.scope,
          service: auth.service
        }
      }))
    }
  }

}


export const constructAuthArgument = (authState) => {
  if (authState.token) {
    return {accessToken: authState.token}
  }

  return {
    username: authState.username,
    password: authState.password,
  }
}

export const unauthorizedAction = ({type, params}) => {
  return (dispatch, getState) => {
    if (type == "Bearer") {
      let {token, username, password, scope} = getState().auth
      if (!token || scope != params.scope) {
        // No token available or token obtained for different scope
        dispatch(authMissingTokenAction(params.realm, params.service, params.scope))
        getToken(params.realm, params.service, params.scope,
          {username, password},
          token => dispatch(authReceiveToken(token)),
          error => dispatch(authMissingCredentialsAction())
        )
      } else {
        // Token already obtained
        dispatch(authMissingCredentialsAction())
      }
    } else {
      dispatch(authMissingCredentialsAction())
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// Repositories
export const requestRepositoriesAction = (action) => ({
    type: "repositories/request",
		action
})

export const receiveRepositoriesAction = (repositories) => ({
    type: "repositories/receive",
    repositories
})

export const receiveErrorRepositoriesAction = (error) => ({
    type: "repositories/error",
    error
})

export const awaitAuthRepositoriesAction = () => ({
    type: "repositories/auth",
})


// Thunk with repository list logic
export const fetchRepositoriesAction = (apiUrl, auth) => {
  return dispatch => {
    dispatch(requestRepositoriesAction())
    getRepositories(
      apiUrl,
      constructAuthArgument(auth),
      repositories => dispatch(receiveRepositoriesAction(repositories)),
      error => dispatch(receiveErrorRepositoriesAction(error)),
			({type, params}) => {
        dispatch(awaitAuthRepositoriesAction()),
        dispatch(unauthorizedAction({type, params}))
      }
    )
  }
}

export const shouldFetchRepositories = (store) => {
  const { isValid, isError, isFetching } = store.repositories
  return !(isValid || isError || isFetching)
}

export const fetchRepositoriesIfNeededAction = (apiUrl, auth) => {
  return (dispatch, getState) => {
    if (shouldFetchRepositories(getState())) {
      dispatch(fetchRepositoriesAction(apiUrl, auth))
    } else {
      return Promise.resolve()
    }
  }
}

export const canFetchRepositories = (store) => {
  return !selectAwaitingAuth(store)
}

export const fetchRepositoriesIfNeededAndPossibleAction = (apiUrl, auth) => {
  return (dispatch, getState) => {
    if (canFetchRepositories(getState())) {
      dispatch(fetchRepositoriesIfNeededAction(apiUrl, auth))
    } else {
      return Promise.resolve()
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// Tags
export const requestTagsAction = (repository) => ({
    type: "tags/request" ,
    repository
})

export const receiveTagsAction = (repository, tags) => ({
    type: "tags/receive",
    repository,
    tags
})

export const receiveErrorTagsAction = (repository, error) => ({
    type: "tags/error",
    repository,
    error
})

export const removeTag = (repository, tag) => ({
    type: "tags/remove",
    repository,
    tag
})

export const awaitAuthTagsAction = (repository) => ({
    type: "tags/auth",
    repository,
})

// Thunk with tags logic
export const fetchTagsAction = (apiUrl, repository, auth) => {
  return dispatch => {
    dispatch(requestTagsAction(repository))
    getTags(
      apiUrl,
      repository,
      constructAuthArgument(auth),
      tags => dispatch(receiveTagsAction(repository, tags)),
      error => dispatch(receiveErrorTagsAction(repository, error)),
      ({type, params}) => {
        dispatch(awaitAuthTagsAction(repository))
        dispatch(unauthorizedAction({type, params}))
      }
    )
  }
}

export const shouldFetchTags = (store, repository) => {
  let tags = store.tags[repository];
  if (!tags) {
    return true
  }

  const { isValid, isError, isFetching } = tags
  return !(isValid || isError || isFetching)
}


export const fetchTagsIfNeededAction = (apiUrl, repository, auth) => {
  return (dispatch, getState) => {
    if (shouldFetchTags(getState(), repository)) {
      dispatch(fetchTagsAction(apiUrl, repository, auth))
    } else {
      return Promise.resolve()
    }
  }
}

export const canFetchTags = (store) => {
  return !selectAwaitingAuth(store)
}

export const fetchTagsIfNeededAndPossibleAction = (apiUrl, repository, auth) => {
  return (dispatch, getState) => {
    if (canFetchTags(getState())) {
      dispatch(fetchTagsIfNeededAction(apiUrl, repository, auth))
    } else {
      return Promise.resolve()
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// Manifest List
export const requestManifestListAction = (repository, tag) => ({
    type: "manifest_list/request",
    repository,
    tag
})

export const receiveManifestListAction = (repository, tag, tagDigest, digests) => ({
    type: "manifest_list/receive",
    repository,
    tag,
    tagDigest,
    digests
})

export const receiveErrorManifestListAction = (repository, tag, error) => ({
    type: "manifest_list/error",
    repository,
    tag,
    error
})

export const awaitAuthManifestListAction = (repository, tag) => ({
    type: "manifest_list/auth",
    repository,
    tag,
})


export const fetchManifestList = (apiUrl, repository, tag, auth) => {
  return dispatch => {
    dispatch(requestManifestListAction(repository, tag))
    getManifest(apiUrl, repository, tag,
      constructAuthArgument(auth),
      (manifest, tagDigest, isList) => {
        if (isList) {
          let digests = manifest.manifests.map(x => ({
            digest: x.digest,
            platform: x.platform,
          }))
          dispatch(receiveManifestListAction(repository, tag, tagDigest, digests))
          digests.forEach(digest =>
            dispatch(fetchManifestIfNeeded(apiUrl, repository, digest.digest, auth)))
        } else {
          let digest = manifest.config.digest;
          dispatch(receiveManifestAction(repository, digest, manifest))
          dispatch(receiveManifestListAction(repository, tag, tagDigest, [{
            digest,
            platform: {
              architecture: 'amd64',
              os: 'linux'
            }
          }]))
          dispatch(fetchImageIfNeeded(apiUrl, repository, digest, auth))
        }
      },
      error => dispatch(receiveErrorManifestListAction(repository, tag, error)),
      ({type, params}) => {
        dispatch(awaitAuthManifestListAction(repository, tag))
        dispatch(unauthorizedAction({type, params}))
      }
    )
  }
}

export const shouldFetchManifestList = (store, repository, tag) => {
  let repoTag = [repository, tag].join("+")
  if (!(repoTag in store.manifestLists)) {
    return true;
  }

  const { isValid, isError, isFetching } = store.manifestLists[repoTag]
  return !(isValid || isError || isFetching)
}

export const fetchManifestListIfNeeded = (apiUrl, repository, tag, auth) => {
  return (dispatch, getState) => {
    if (shouldFetchManifestList(getState(), repository, tag)) {
      dispatch(fetchManifestList(apiUrl, repository, tag, auth))
    }
  }
}

export const canFetchManifestList = (store, repository, tag) => {
  return !selectAwaitingAuth(store)
}

export const fetchManifestListIfNeededAndPossible = (apiUrl, repository, tag, auth) => {
  return (dispatch, getState) => {
    if (canFetchManifestList(getState(), repository, tag)) {
      dispatch(fetchManifestListIfNeeded(apiUrl, repository, tag, auth))
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// Manifest
export const requestManifestAction = (repository, digest) => ({
    type: "manifest/request",
    repository,
    digest
})

export const receiveManifestAction = (repository, digest, manifest) => ({
    type: "manifest/receive",
    repository,
    digest,
    manifest
})

export const awaitAuthManifestAction = (repository, digest) => ({
    type: "manifest/auth",
    repository,
    digest,
})

export const receiveErrorManifestAction = (repository, digest, error) => ({
    type: "manifest/error",
    repository,
    digest,
    error
})


export const fetchManifest = (apiUrl, repository, digest, auth) => {
  return dispatch => {
    dispatch(requestManifestAction(repository, digest))
    getManifest(apiUrl, repository, digest,
      constructAuthArgument(auth),
      (manifest, _, isList) => {
        if (isList) {
          dispatch(receiveErrorManifestAction(repository, digest))
        } else {
          dispatch(receiveManifestAction(repository, digest, manifest))
          let imageDigest = manifest.config.digest
          dispatch(fetchImageIfNeeded(apiUrl, repository, imageDigest, auth))
        }
      },
      error => dispatch(receiveErrorManifestAction(repository, digest, error)),
      ({type, params}) => {
        dispatch(awaitAuthManifestAction(repository, digest))
        dispatch(unauthorizedAction({type, params}))
      }
    )
  }
}

export const shouldFetchManifest = (store, repository, digest) => {
  let repoDigest = [repository, digest].join("+")
  if (!(repoDigest in store.manifests)) {
    return true;
  }

  const { isValid, isError, isFetching } = store.manifests[repoDigest]
  return !(isValid || isError || isFetching)

}

export const fetchManifestIfNeeded = (apiUrl, repository, digest, auth) => {
  return (dispatch, getState) => {
    if (shouldFetchManifest(getState(), repository, digest)) {
      dispatch(fetchManifest(apiUrl, repository, digest, auth))
    }
  }
}

export const canFetchManifest = (store, repository, tag) => {
  return !selectAwaitingAuth(store)
}

export const fetchManifestIfNeededAndPossible = (apiUrl, repository, digest, auth) => {
  return (dispatch, getState) => {
    if (canFetchManifest(getState(), repository, digest)) {
      dispatch(fetchManifestIfNeeded(apiUrl, repository, digest, auth))
    }
  }
}

///////////////////////////////////////////////////////////////////////////////
// Image
export const requestImageAction = (repository, digest) => ({
    type: "image/request",
    repository,
    digest
})

export const receiveImageAction = (repository, digest, image) => ({
    type: "image/receive",
    repository,
    digest,
    image
})

export const awaitAuthImageAction = (repository, digest) => ({
    type: "image/auth",
    repository,
    digest,
})

export const receiveErrorImageAction = (repository, digest, error) => ({
    type: "image/error",
    repository,
    digest,
    error
})

export const fetchImage = (apiUrl, repository, digest, auth) => {
  return dispatch => {
    dispatch(requestImageAction(repository, digest))
    getImage(apiUrl, repository, digest,
      constructAuthArgument(auth),
      image => dispatch(receiveImageAction(repository, digest, image)),
      error => dispatch(receiveErrorImageAction(repository, digest, error)),
      ({type, params}) => {
        dispatch(awaitAuthImageAction(repository, digest))
        dispatch(unauthorizedAction({type, params}))
      }
    )
  }
}

export const shouldFetchImage = (store, repository, digest) => {
  let repoDigest = [repository, digest].join("+")
  if (!(repoDigest in store.images)) {
    return true;
  }

  const { isValid, isError, isFetching } = store.images[repoDigest]
  return !(isValid || isError || isFetching)
}

export const fetchImageIfNeeded = (apiUrl, repository, digest, auth) => {
  return (dispatch, getState) => {
    if (shouldFetchImage(getState(), repository, digest)) {
      dispatch(fetchImage(apiUrl, repository, digest, auth))
    }
  }
}

export const canFetchImage = (store, repository, digest) => {
  return !selectAwaitingAuth(store)
}

export const fetchImageIfNeededAndPossible = (apiUrl, repository, digest, auth) => {
  return (dispatch, getState) => {
    if (canFetchImage(getState(), repository, digest)) {
      dispatch(fetchImageIfNeeded(apiUrl, repository, digest, auth))
    }
  }
}

export const fetchTagDataIfNeeded = fetchManifestListIfNeeded
export const fetchTagDataIfNeededAndPossible = fetchManifestListIfNeededAndPossible

///////////////////////////////////////////////////////////////////////////////
// Delete
export const promptConfirmDelete = (repository, tag, tagDigest) => ({
    type: "delete/prompt",
    repository,
    tag,
    tagDigest
})

export const resetDeletePrompt = () => ({
    type: "delete/reset",
})

export const confirmDelete = () => ({
    type: "delete/confirm",
})

export const requestDeletion = (apiUrl, repository, tag, auth, goHome) => {
  return (dispatch, getState) => {
    deleteTag(
      apiUrl,
      repository,
      getState().del.tagDigest,
      constructAuthArgument(auth),
      success => {
        dispatch(resetDeletePrompt())
        dispatch(removeTag(repository, tag))
        goHome()
      },
      error => {console.log("TODO")},
      ({type, params}) => {
        dispatch(awaitAuthTagsAction(repository))
        dispatch(unauthorizedAction({type, params}))
      }
    )
  }
}

export const canDelete = (store) => {
  return store.del.confirmed && !selectAwaitingAuth(store)

}

export const requestDeletionIfPossible = (apiUrl, repository, tag, auth, goHome) => {
  return (dispatch, getStore) => {
    if (canDelete(getStore())) {
      dispatch(requestDeletion(apiUrl, repository, tag, auth, goHome))
    }
  }
}
