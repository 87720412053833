
import update from 'immutability-helper';
import { selectManifestList } from '../reducers/manifest_lists.js'
import { selectManifest} from '../reducers/manifest.js'

const initialState = {
  isFetching: false,
  isValid: false,
  isError: false,
  awaitingAuth: false,
  error: null,
  image: {},
}

const singleImageReducer = (image=initialState, action) => {
  switch (action.type) {
    case "image/request":
      return Object.assign({}, image, {isFetching: true,
                                       isError: false})

    case "image/receive":
      return Object.assign({}, image, {isFetching: false,
                                       isValid: true,
                                       image: action.image,
                                       awaitingAuth: false,
                                       isError: false})

    case "image/error":
      return Object.assign({}, image, {isFetching: false,
                                       isValid: false,
                                       isError: true,
                                       awaitingAuth: false,
                                       error: action.error})
    case "image/auth":
      return Object.assign({}, image, {isFetching: false,
                                       isValid: false,
                                       isError: false,
                                       awaitingAuth: true})

    default:
      return image
  }

}

const imageReducer = (image={}, action) => {
  let slice = initialState;
  let repoDigest = [action.repository, action.digest].join("+")
  switch (action.type) {
    case "image/request":
    case "image/receive":
    case "image/error":
    case "image/auth":
      if (repoDigest in image) {
        slice = image[repoDigest];
      }

      slice = singleImageReducer(slice, action)
      return Object.assign({}, image, {[repoDigest]: slice})

    default:
      return image
  }
}

export const selectImage = (repository, digest) => (state) => {
  let repoDigest = [repository, digest].join("+")
  if (repoDigest in state.images) {
    return state.images[repoDigest];
  } else {
    return initialState;
  }
}

export const selectTagData = (repository, tag) => (state) => {
  let manifestList = selectManifestList(repository, tag)(state)

  const augmentManifest = (manifest) => {
    if (manifest.isValid) {
      return Object.assign({}, manifest, {image: selectImage(repository, manifest.manifest.config.digest)(state)})
    }
    return Object.assign({}, manifest)
  }

  return Object.assign(
    {},
    manifestList,
    {digests: manifestList.digests.map(
      manifestRef => Object.assign(
        {},
        manifestRef,
        {manifest: augmentManifest(selectManifest(repository, manifestRef.digest)(state))})
      )}
    )
}

export default imageReducer
