
import rest from 'restler'

export const parseWwwAuth = (header) => {
  let arr = header.split(" ")
  let type = arr.slice(0, 1)[0]
  let remainder = arr.join(" ")

  let re = RegExp('([a-zA-z]+)="([^"]*),?"', "g")
  let part
  let params = {}
  while (part = re.exec(remainder)) {
    params[part[1]] = part[2]
  }
  return {type, params}
}

export const getToken = (realm, service, scope, options, success, error) => {
  if (!success) { success = () => {} }
  if (!error) { error = () => {} }

  let opts = Object.assign({}, options, {
    headers: {
      'User-Agent': "Dukk",
      'Accept': 'application/json'
      }
  })

  rest.get(realm + "?service=" + service + "&scope=" + scope, opts).on('complete', (result, response) => {
    if (result instanceof Error) {
      error([result.message])
    } else {
			let statusCode = response.statusCode
			if (statusCode >= 400) {
				error()
			} else {
				let token = result.access_token;
				if (!token) {
					error(result.errors)
				} else {
					success(token)
				}
			}
    }
  })
}

export const getRegistry = (apiUrl, options, success, error, unauth) => {
  if (!success) { success = () => {} }
  if (!error) { error = () => {} }
  if (!unauth) { unauth = () => {} }

  let opts = Object.assign({}, options, {
    headers: {
      'User-Agent': "Dukk",
      'Accept': 'application/vnd.docker.distribution.manifest.v2+json'
      }
  })

  rest.get(apiUrl + "/_catalog", opts).on('complete', (result, response) => {
    if (result instanceof Error) {
      error([result.message])
    } else {
			let statusCode = response.statusCode
			if (statusCode == 401) {
				unauth(parseWwwAuth(response.headers["www-authenticate"]))
			} else if (statusCode >= 400) {
				error([`Invalid status code ${statusCode}`])
			} else {
				let repositories = result.repositories;
				if (!repositories) {
					error(result.errors)
				} else {
					success(repositories)
				}
			}
    }
  })
}
export const getRepositories = getRegistry

export const getTags = (apiUrl, repository, options, success, error, unauth) => {
  if (!success) { success = () => {} }
  if (!error) { error = () => {} }
  if (!unauth) { unauth = () => {} }

  let opts = Object.assign({}, options, {
    headers: {
      'User-Agent': "Dukk",
      }
  })

  rest.get(apiUrl + "/" + repository + "/tags/list", opts).on('complete', (result, response) => {
    if (result instanceof Error) {
      error([result.message])
    } else {
			let statusCode = response.statusCode
			if (statusCode == 401) {
				unauth(parseWwwAuth(response.headers["www-authenticate"]))
			} else if (statusCode >= 400) {
				error([`Invalid status code ${statusCode}`])
			} else {
				let tags = result.tags;
				if (!("tags" in result)) {
					error(result.errors)
				} else {
					success(tags || [])
				}
			}

    }
  })
}

export const deleteTag = (apiUrl, repository, tag, options, success, error, unauth) => {
  if (!success) { success = () => {} }
  if (!error) { error = () => {} }
  if (!unauth) { unauth = () => {} }

  let opts = Object.assign({}, options, {
    headers: {
      'User-Agent': "Dukk",
      }
  })

  rest.del(apiUrl + "/" + repository + "/manifests/" + tag, opts).on('complete', (result, response) => {
    if (result instanceof Error) {
      error([result.message])
    } else {
			let statusCode = response.statusCode
			if (statusCode == 401) {
				unauth(parseWwwAuth(response.headers["www-authenticate"]))
			} else if (statusCode >= 400) {
				error([`Invalid status code ${statusCode}`])
			} else {
        success()
			}

    }
  })
}


export const getManifest =(apiUrl, repository, reference, options, success, error, unauth) => {
  if (!success) { success = () => {} }
  if (!error) { error = () => {} }
  if (!unauth) { unauth = () => {} }

  let opts = Object.assign({}, options, {
    headers: {
      'User-Agent': "Dukk",
      'Accept': 'application/vnd.docker.distribution.manifest.list.v2+json, ' + 
                'application/vnd.docker.distribution.manifest.v2+json, ' + 
                'application/json;q=0.9'

      }
  })
  rest.get(apiUrl + "/" + repository + "/manifests/" + reference, opts)
  .on('complete', (result, response) => {
    if (result instanceof Error) {
      error([result.message])
    } else {
			let statusCode = response.statusCode
			if (statusCode == 401) {
				unauth(parseWwwAuth(response.headers["www-authenticate"]))
			} else if (statusCode >= 400) {
				error([`Invalid status code ${statusCode}`])
			} else {
        if (!result) {
          error(result.errors)
        }
        let contentType = response.headers['content-type']
        switch (contentType) {
          case "application/vnd.docker.distribution.manifest.v2+json":
            success(result, response.headers['docker-content-digest'], false)
            break
          case "application/vnd.docker.distribution.manifest.list.v2+json":
            success(result, response.headers['docker-content-digest'], true)
            break
          default:
            error([`Unknown manifest reponse type '${contentType}'`])
        }
      }
    }
  })
}

export const getImage =(apiUrl, repository, digest, options, success, error, unath) => {
  if (!success) { success = () => {} }
  if (!error) { error = () => {} }
  if (!unath) { unath = () => {} }

  let opts = Object.assign({}, options, {
    headers: {
      'User-Agent': "Dukk",
      }
  })
  rest.get(apiUrl + "/" + repository + "/blobs/" + digest, opts).on('complete', (result, response) => {
    if (result instanceof Error) {
      error([result.message])
    } else {
			let statusCode = response.statusCode
			if (statusCode == 401) {
				unauth(parseWwwAuth(response.headers["www-authenticate"]))
			} else if (statusCode >= 400) {
				error([`Invalid status code ${statusCode}`])
			} else {
        success(JSON.parse(result))
      }
    }
  })
}

export const deleteImage = (apiUrl, repository, reference) => {}
