
import { combineReducers } from 'redux'
import repositories from './repositories.js'
import tags from './tags.js'
import manifestLists from './manifest_lists.js'
import manifests from './manifest.js'
import images from './image.js'
import auth from './auth.js'
import del from './del.js'

const apiUrl = (url=https://docker.sauerburger.com/v2, action) => url
const registryHost = (host=docker.sauerburger.com, action) => host

export const selectApi = (store) => store.apiUrl
export const selectRegistryHost = (store) => store.registryHost

const rootReducer = combineReducers({
  del,
	auth,
	registryHost,
  apiUrl,
  repositories,
  tags,
  manifestLists,
  manifests,
  images,
})

export default rootReducer
