
import update from 'immutability-helper';

const initialState = {
  isFetching: false,
  isValid: false,
  isError: false,
  awaitingAuth: false,
  error: null,
  list: [],
}

const repositoriesReducer = (repositories=initialState, action) => {
  switch (action.type) {
    case "repositories/request":
      return Object.assign({}, repositories, {isFetching: true})

    case "repositories/receive":
      return {
        isFetching: false,
        isValid: true,
        isError: false,
        awaitingAuth: false,
        error: null,
        list: action.repositories.map(x => x)
      }

    case "repositories/auth":
      return Object.assign({}, {
          isFetching: false,
          isValid: false,
          isError: false,
          awaitingAuth: true,
        })

    case "repositories/error":
      return Object.assign({}, {
          isFetching: false,
          isValid: false,
          isError: true,
          awaitingAuth: false,
          error: action.error
        })
    default:
      return repositories
  }
}

export const selectRepositories = (state) => {
  return state.repositories
}

export default repositoriesReducer
