
import React, { useEffect } from 'react'
import { useDispatch,  useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { selectTags } from '../reducers/tags.js'
import { selectApi } from '../reducers'
import { selectAwaitingAuth } from '../reducers/auth.js'
import { fetchTagsIfNeededAndPossibleAction } from '../actions'

import ListGroup from 'react-bootstrap/ListGroup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCubes, faTag } from '@fortawesome/free-solid-svg-icons'

const Repositories = () => {

  let { repository } = useParams();

  let dispatch = useDispatch();
  let tags = useSelector(selectTags(repository))
  let apiUrl = useSelector(selectApi)
  let auth = useSelector(store => store.auth)
  let awaitingAuth = useSelector(selectAwaitingAuth)

  useEffect(() => {
    dispatch(fetchTagsIfNeededAndPossibleAction(apiUrl, repository, auth))
  })

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ul className="mt-2 breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/"}>Repositories</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to={ "/" + repository }>
              <FontAwesomeIcon icon={faCubes} /> { repository }
            </Link>
          </li>
        </ul>
      </nav>
      <h2>Tags</h2>
      { tags.isFetching && <p>loading...</p> }
      { awaitingAuth && <p>Need authorization...</p> }
      { tags.isError && tags.error.map(
        (error, i) => <p key={i} style={{color: 'red'}}>{JSON.stringify(error)}</p>) }
      { tags.isValid &&
        <>
          { tags.list.length==0 && <p>There are no tags. Use docker tag + docker push</p> }
          { tags.list.length>0 && 
            <ListGroup>
            { tags.list.map((tag, index) => 
              <ListGroup.Item action key={index} href={"/" + repository + ":" + tag}>
                <FontAwesomeIcon icon={faTag} /> { tag }
              </ListGroup.Item>)
            }
            </ListGroup>
          }
        </>
      }
    </div>)
}

export default Repositories
