
import React, { useEffect } from 'react'
import { useDispatch,  useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { selectRepositories } from '../reducers/repositories.js'
import { selectApi } from '../reducers'
import { selectAwaitingAuth } from '../reducers/auth.js'
import { fetchRepositoriesIfNeededAndPossibleAction } from '../actions'

import ListGroup from 'react-bootstrap/ListGroup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCubes } from '@fortawesome/free-solid-svg-icons'

const Repositories = () => {

  let dispatch = useDispatch();
  let repositories = useSelector(selectRepositories)
  let auth = useSelector(store => store.auth)
  let apiUrl = useSelector(selectApi)
  let awaitingAuth = useSelector(selectAwaitingAuth)

  useEffect(() => {
    dispatch(fetchRepositoriesIfNeededAndPossibleAction(apiUrl, auth))
  })

  return (
    <div>
      <nav aria-label="breadcrumb">
        <ul className="mt-2 breadcrumb">
          <li className="breadcrumb-item">
            <Link to={"/"}>Repositories</Link>
          </li>
        </ul>
      </nav>
      <h2>Repositories</h2>
      { repositories.isFetching && <p>loading...</p> }
      { awaitingAuth && <p>Need authorization...</p> }
      { repositories.isError && repositories.error.map(
        (error, i) => <p key={i} style={{color: 'red'}}>{JSON.stringify(error)}</p>) }
      { repositories.isValid && 
        <ListGroup>
        { repositories.list.map((repository, index) => 
          <ListGroup.Item action key={index} href={"/"+ repository}>
            <FontAwesomeIcon icon={faCubes} /> { repository }
          </ListGroup.Item>)
        }
        </ListGroup>
      }
    </div>)
}

export default Repositories
