
const initialState = {
	missingCredentials: false,
	username: "",
	password: "",
	missingToken: false,
  token: "",
  realm: "",
  service: "",
  scope: "",
}

export const auth = (authState=initialState, action) => {
	switch (action.type) {
		case "auth/missing_token":
			return Object.assign({}, authState, {missingToken: true,
        realm: action.realm,
        service: action.service,
        scope: action.scope})
		case "auth/missing_credentials":
			return Object.assign({}, authState, {missingCredentials: true})
    case "auth/receive_credentials":
			return Object.assign({}, authState,
                           {
                             missingCredentials: false,
                             username: action.username,
                             token: "",
                             password: action.password
                           })
    case "auth/receive_token":
			return Object.assign({}, authState, {token: action.token, missingToken: false})
		default:
			return authState
	}
}

export const selectAwaitingCredentials = (store) => {
  return store.auth.missingCredentials
}
export const selectAwaitingToken = (store) => {
  return store.auth.missingToken
}

export const selectAwaitingAuth = (store) => {
  return selectAwaitingCredentials(store) || selectAwaitingToken(store)
}

export default auth
