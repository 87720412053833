

import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const PanelData = ({title, icon, value, prepend, append}) => (
  <PanelDataList title={title} icon={icon} values={[{
      value: value,
      append: append,
      prepend: prepend
  }]} />
)

export default PanelData

export const PanelDataList = ({title, icon, values}) => (
      <div className="form-group">
        <label className="text-muted pl-2" htmlFor="image-name">
          { icon && <FontAwesomeIcon icon={icon} /> } { title }
        </label>
        { values.map((v, i) => (
          <div key={i} style={{fontFamily: 'monospace'}} className="input-group my-1">
            { v.prepend && 
            <div className="input-group-prepend">
              <span className="input-group-text">{ v.prepend }</span>
            </div>
            }
            <input id="image-name" type="text" readOnly className="bg-white form-control" value={v.value} />
            { v.append && 
            <div className="input-group-append">
              <span className="input-group-text">{ v.append }</span>
            </div>
            }
          </div>
        ))}
      </div>
)
