
import React, { useEffect } from 'react'
import { useDispatch,  useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { selectApi, selectRegistryHost } from '../reducers'
import { selectTagData } from '../reducers/image.js'
import { selectAwaitingAuth } from '../reducers/auth.js'
import { fetchTagDataIfNeededAndPossible, promptConfirmDelete } from '../actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode,
         faTag,
         faTrash,
         faCubes,
         faGlobe,
         faLayerGroup,
         faServer,
         faUser,
         faCog,
         faTerminal,
         faFolder,
         faDollarSign,
         faHistory,
         faFingerprint } from '@fortawesome/free-solid-svg-icons'

import Panel from './Panel.jsx'
import PanelData, { PanelDataList } from './PanelData.jsx'
import PanelDt from './PanelDt.jsx'

const chop = (str) => {
  if (!str) {
    return ""
  }
  let chopStrs = [
    "/bin/sh -c #(nop)  ",
    "/bin/sh -c #(nop) ",
    "/bin/sh -c ",
    "|2 "
   ]

  chopStrs.forEach(chopStr => {
    if (str.startsWith(chopStr)) {
      str = str.substr(chopStr.length)
    }
  })

  return str
}


const SingleImage = ({manifest, image}) => {

  return (<>
    <PanelData title="Image ID" icon={faFingerprint} 
          prepend={manifest.config.digest.split(":")[0]}
          value={manifest.config.digest.split(":")[1]} />
    <PanelData title="Layers" value={manifest.layers.length} icon={faLayerGroup} />
    <PanelData title="Architecture" value={image.architecture} icon={faLaptopCode} />
    { image.config.hostname && 
      <PanelData title="Hostname" value={image.config.hostname} icon={faServer} />
    }
    { image.config.user && 
      <PanelData title="User" value={image.config.user} icon={faUser} />
    }
    { image.os && 
      <PanelData title="Operating system" value={image.os} icon={faCog} />
    }

    { image.config.Env && 
      <PanelDataList title="Environment" icon={faDollarSign}
        values={ image.config.Env.map((x, i) => ({
          prepend: x.split("=")[0],
          value: x.split("=")[1]
        }))} />
    }

    { image.config.Cmd && 
     <PanelData title="Command" value={image.config.Cmd.join("")} icon={faTerminal} />
    }
    { image.config.WorkingDir &&
      <PanelData title="Working directory" value={image.config.WorkingDir} icon={faFolder} />
    }

         { image.config.Volumes && 
           <><p>Volumes:</p>
           <ul>
            { Object.keys(image.config.Volumes).map((x, i) => <li className="code" key={i}>{x}</li>)}
           </ul></>
         }
    { image.config.Entrypoint && 
      <PanelData title="Entryoint" value={image.config.Entrypoint.join("")} icon={faTerminal} />
    }
    { image.config.Labels && 
      <PanelDataList title="Labels" icon={faTag}
        values={ Object.keys(image.config.Labels).map((x, i) => ({
          prepend: x,
          value: image.config.Labels[x],
        }))} />
    }
    { image.config.Volumes && 
      <PanelDataList title="Labels" icon={faTag}
        values={ Object.keys(image.config.Volumes).map((x, i) => ({
          value: x
        }))} />
    }
    { image.history && 
      <PanelDataList title="History" icon={faHistory}
        values={ image.history.map((x, i) => ({
          prepend: chop(x.created_by).split(/\s+/)[0],
          value: chop(x.created_by).split(/\s+/).slice(1).join(" ")
        }))} />
    }
	</>)
}

const Images = () => {

  let { repository, tag } = useParams();

  let dispatch = useDispatch();
  let tagData = useSelector(selectTagData(repository, tag))
  let apiUrl = useSelector(selectApi)
  let registryHost = useSelector(selectRegistryHost)
  let auth = useSelector(store => store.auth)
  let awaitingAuth = useSelector(selectAwaitingAuth)

  useEffect(() => {
    dispatch(fetchTagDataIfNeededAndPossible(apiUrl, repository, tag, auth))
  })

  return (<div>
    <nav aria-label="breadcrumb">
      <ul className="mt-2 breadcrumb">
        <li className="breadcrumb-item">
          <Link to={"/"}>Repositories</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={ "/" + repository }>
            <FontAwesomeIcon icon={faCubes} /> { repository }
          </Link>
        </li>
        <li className="breadcrumb-item">
          <Link to={ `/${repository}:${tag}` }>
            <FontAwesomeIcon icon={faTag} /> { tag }
          </Link>
        </li>
      </ul>
    </nav>
    <h2 className="d-flex justify-content-between">
      <div>Tags</div>
      <div>
        <button className="btn btn-outline-primary"
                onClick={() => dispatch(promptConfirmDelete(repository, tag, tagData.tagDigest))}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </h2>
    <Panel title={`${repository}:${tag}`} icon={faTag}>
      <PanelData title="Image name" icon={faGlobe} prepend="docker pull" value={registryHost + "/" + repository + ":" + tag} />
      { tagData.isFetching && 
      <div className="text-center">
        <div className="spinner-border m-1 text-secondary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      }
      { awaitingAuth &&
        <p className="text-warning">Need authorization...</p> }
      { tagData.isError &&
        <p className="text-danger">{JSON.stringify(tagData.error)}</p> }
      { tagData.isValid && tagData.tagDigest &&
        <PanelData title="Tag ID" icon={faFingerprint} 
              prepend={tagData.tagDigest.split(":")[0]}
              value={tagData.tagDigest.split(":")[1]} />
      }
    </Panel>

          { /* <button onClick={() => dispatch(promptConfirmDelete(repository, tag, tagData.tagDigest))}>Delete</button>*/ }

    <h2>Images</h2>
    { tagData.digests.map((manifestRef, i) => 
      <Panel key={i} icon={faLaptopCode} title={`Platform: ${manifestRef.platform.architecture}, ${manifestRef.platform.os}`}>
          { manifestRef.manifest.isFetching && <p>loading...</p> }
          { (manifestRef.manifest.isValid && manifestRef.manifest.image.isValid) &&
          <SingleImage manifest={manifestRef.manifest.manifest} image={manifestRef.manifest.image.image} />}
      </Panel>
    )}
  </div>)
}

export default Images

