
import update from 'immutability-helper';

const initialState = {
  isFetching: false,
  isValid: false,
  isError: false,
  awaitingAuth: false,
  error: null,
  list: [],
}

const singleTagReducer = (tags=initialState, action) => {
  switch (action.type) {
    case "tags/request":
      return Object.assign({}, tags, {isFetching: true,
                                      isError: false})

    case "tags/receive":
      return Object.assign({}, tags, {isFetching: false,
                                      isValid: true,
                                      awaitingAuth: false,
                                      list: action.tags.map(x => x),
                                      isError: false})
    case "tags/remove":
      return Object.assign({}, tags,
                           {list: tags.list.filter(x => x != action.tag)})

    case "tags/error":
      return Object.assign({}, tags, {isFetching: false,
                                      isValid: false,
                                      isError: true,
                                      awaitingAuth: false,
                                      error: action.error})

    case "tags/auth":
      return Object.assign({}, tags, {isFetching: false,
                                      isValid: false,
                                      isError: false,
                                      awaitingAuth: true})

    default:
      return tags
  }

}

const tagsReducer = (tags={}, action) => {
  let slice = initialState;
  switch (action.type) {
    case "tags/remove":
    case "tags/request":
    case "tags/receive":
    case "tags/auth":
    case "tags/error":
      if (action.repository in tags) {
        slice = tags[action.repository];
      }

      slice = singleTagReducer(slice, action)
      return Object.assign({}, tags, {[action.repository]: slice})

    default:
      return tags
  }
}

export const selectTags = (repository) => (state) => {
  if (repository in state.tags) {
    return state.tags[repository];
  } else {
    return initialState;
  }
}

export default tagsReducer
