
import React, { useEffect } from 'react'
import { useDispatch,  useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectDeleteConfirmation } from '../reducers/del'
import { useHistory } from 'react-router'
import { selectApi } from '../reducers'
import { selectAwaitingAuth } from '../reducers/auth'
import { confirmDelete, requestDeletionIfPossible } from '../actions'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


const Delete = () => {

  let confirmation = useSelector(selectDeleteConfirmation)
  let apiUrl = useSelector(selectApi)
  let auth = useSelector(store => store.auth)
  let awaitingAuth = useSelector(selectAwaitingAuth)
  let dispatch = useDispatch()

  let history = useHistory();
  const goHome = () => history.push("/")

  useEffect(() => 
    dispatch(requestDeletionIfPossible(apiUrl, confirmation.repository, confirmation.tag, auth, goHome))
  )

	return (<Modal show={confirmation.awaitConfirmation} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Delete image</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          Do you really want to delete the image{' '}
          <code>{confirmation.repository}:{confirmation.tag}</code>?
        </Modal.Body>
      
        <Modal.Footer>
          <Button onClick={() => dispatch(confirmDelete())} 
                  type="button" variant="primary">
            Delete
          </Button>
        </Modal.Footer>
		</Modal>)
}

export default Delete
