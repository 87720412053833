

import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

const Panel = ({title, children, icon}) => (
    <div className="border my-2 rounded bg-light">
      <h3 className="bg-dark text-light px-3 py-2 rounded-top">
        { icon && <FontAwesomeIcon icon={icon} />} { title }
      </h3>
      { React.Children.map(children, (row, i) => (
      row && <div className={classNames('px-4', 'py-2', {'border-bottom': i < children.length - 1})}>
        { row }
      </div>
      ))}
    </div>
)

export default Panel
