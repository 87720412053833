
import React, { useEffect } from 'react'
import { useDispatch,  useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { resolveAuthIssue } from '../actions'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


const Auth = () => {

  let auth = useSelector((store) => store.auth)

  let usernameRef = React.createRef()
  let passwordRef = React.createRef()

  let dispatch = useDispatch();
  let onSubmit = (event) => {
    event.preventDefault()
    dispatch(resolveAuthIssue(
      usernameRef.current.value,
      passwordRef.current.value
    ))
  }
	return (<Modal show={auth.missingCredentials} backdrop="static" keyboard={false}>
      <form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Authentication required</Modal.Title>
        </Modal.Header>
      
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input className="form-control" placeholder="Username"
                   type="text" id="username" ref={usernameRef} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input className="form-control" placeholder="Password"
                   type="password" id="password" ref={passwordRef} />
          </div>
        </Modal.Body>
      
        <Modal.Footer>
          <Button as="input" type="submit" variant="primary" value="Login" />
        </Modal.Footer>
      </form>
		</Modal>)

}

export default Auth
